export enum ResponseType {
  SUCCESS = "success",
  ERROR = "error",
}

interface SuccessResponse<T> {
  status: ResponseType.SUCCESS;
  data: T;
}

interface SuccessResponseVoid {
  // todo dont call it void
  status: ResponseType.SUCCESS;
}

export interface ErrorResponse {
  status: ResponseType.ERROR;
  error: string;
}

export type ActionResponse<T> = SuccessResponse<T> | ErrorResponse;
export type ActionResponseVoid = SuccessResponseVoid | ErrorResponse;

export interface PaginationRequest {
  page?: number;
  pageSize?: number;
}
export interface PaginationResponse<T> {
  totalRecords: number;
  currentPage: number;
  totalPages: number;
  items: T[];
}
